import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaUser, FaLock } from "react-icons/fa";

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch("http://127.0.0.1:8000/api/login/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ username, password }),
            });

            if (!response.ok) {
                const data = await response.json();
                setError(data.message || "Invalid username or password");
                return;
            }

            // On successful login
            const data = await response.json();
            console.log("Login successful:", data);

            // Save authentication token or session if needed
            localStorage.setItem("authToken", data.token || "testToken"); // Example token

            // Redirect to Customers page
            navigate("/dashboard/customers");
        } catch (error) {
            console.error("Error during login:", error);
            setError("An error occurred. Please try again.");
        }
    };

    return (
        <div className="login-container">
            <div className="login-box">
                <h2 className="login-heading">Login to Dashboard</h2>
                <form onSubmit={handleLogin}>
                    <div className="form-group">
                        <div className="input-icon">
                            <FaUser className="icon" />
                            <input
                                type="text"
                                id="username"
                                className="form-control"
                                placeholder="Enter your username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="input-icon">
                            <FaLock className="icon" />
                            <input
                                type="password"
                                id="password"
                                className="form-control"
                                placeholder="Enter your password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                    </div>

                    {error && <div className="alert alert-danger">{error}</div>}

                    <button type="submit" className="btn btn-primary btn-login">
                        Login
                    </button>
                </form>
            </div>

            {/* Embedded CSS */}
            <style>{`
                .login-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-height: 100vh;
                    background: linear-gradient(120deg, #3498db, #8e44ad);
                    margin: 0;
                }

                .login-box {
                    background: #ffffff;
                    border-radius: 12px;
                    padding: 30px;
                    max-width: 400px;
                    width: 100%;
                    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
                    text-align: center;
                }

                .login-heading {
                    font-size: 24px;
                    font-weight: bold;
                    color: #333333;
                    margin-bottom: 20px;
                }

                .form-group {
                    margin-bottom: 20px;
                    position: relative;
                }

                .input-icon {
                    display: flex;
                    align-items: center;
                    border: 1px solid #ccc;
                    border-radius: 8px;
                    padding: 10px;
                    background: #f9f9f9;
                }

                .input-icon .icon {
                    font-size: 20px;
                    color: #3498db;
                    margin-right: 10px;
                }

                .input-icon .form-control {
                    border: none;
                    outline: none;
                    flex: 1;
                    padding: 5px 10px;
                    font-size: 16px;
                    background: transparent;
                }

                .input-icon .form-control::placeholder {
                    color: #aaa;
                }

                .input-icon .form-control:focus {
                    background-color: #fff;
                }

                .alert {
                    font-size: 14px;
                    background-color: #e74c3c;
                    color: white;
                    padding: 10px;
                    border-radius: 5px;
                    margin-bottom: 10px;
                }

                .btn-login {
                    width: 100%;
                    padding: 12px;
                    background-color: #3498db;
                    border: none;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 600;
                    border-radius: 8px;
                    cursor: pointer;
                    transition: background-color 0.3s ease-in-out;
                }

                .btn-login:hover {
                    background-color: #2980b9;
                }
            `}</style>
        </div>
    );
};

export default Login;
