import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"; // Import for navigation
import './Dashboard.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

const DataTable = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [data, setData] = useState([]); // Data state
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const navigate = useNavigate(); // Navigation for edit

  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  // Fetch data from backend API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("http://127.0.0.1:8000/api/get-all-keys/"); // Replace with your endpoint
        if (!response.ok) {
          throw new Error("Failed to fetch data.");
        }
        const result = await response.json();
        setData(result); // Assuming the response is an array of objects
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to load data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Delete functionality
  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this item?");
    if (!confirmDelete) return;

    try {
      const response = await fetch(`http://127.0.0.1:8000/api/delete-key/${id}/`, { // Replace with your delete endpoint
        method: "DELETE",
      });

      if (!response.ok) {
        throw new Error("Failed to delete the item.");
      }

      // Update the local state after deletion
      setData(data.filter((item) => item.id !== id));
      alert("Item deleted successfully!");
    } catch (error) {
      console.error("Error deleting item:", error);
      alert("Failed to delete the item. Please try again.");
    }
  };

  // Edit functionality
  const handleEdit = (id) => {
    navigate(`/dashboard/edit/${id}`); // Navigate to edit form with ID
  };

  return (
    <div className={`dashboard ${sidebarCollapsed ? "collapsed" : ""}`}>
      {/* Sidebar */}
      <div id="sidebar" className={`sidebar ${sidebarCollapsed ? "collapsed" : ""}`}>
        <div className="logo">
          <span className={`full-text ${sidebarCollapsed ? "d-none" : ""}`}>Malspy</span>
          <button className="toggle-btn" onClick={toggleSidebar}>
            &#9776;
          </button>
        </div>
        <nav className="nav flex-column">
          <Link className="nav-link" to="/dashboard/">
            <span className="icon">&#128178;</span>
            <span className={`text ${sidebarCollapsed ? "d-none" : ""}`}>Packages</span>
          </Link>
         
          <Link className="nav-link" to="/dashboard/new-user">
            <span className="icon">&#128100;</span>
            <span className={`text ${sidebarCollapsed ? "d-none" : ""}`}>New User</span>
          </Link>
          <Link className="nav-link" to="/dashboard/customers">
            <span className="icon">&#128179;</span>
            <span className={`text ${sidebarCollapsed ? "d-none" : ""}`}>Customers</span>
          </Link>
          <Link className="nav-link" to="/logout">
            <span className="icon">&#128682;</span>
            <span className={`text ${sidebarCollapsed ? "d-none" : ""}`}>Logout</span>
          </Link>
        </nav>
      </div>

      {/* Content */}
      <div id="content" className="content">
        <h1 style={{ marginLeft: "90px", marginTop: "10px" }}>Data Table</h1>
        {loading ? (
          <p className="text-center">Loading...</p>
        ) : error ? (
          <p className="text-center text-danger">{error}</p>
        ) : (
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>
                    <i className="bi bi-person-fill"></i> Name
                  </th>
                  <th>
                    <i className="bi bi-key-fill"></i> API Key
                  </th>
                  <th>
                    <i className="bi bi-bar-chart-line"></i> Usage
                  </th>
                  <th>
                    <i className="bi bi-gear-fill"></i> Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr key={item.id}>
                    <td>{item.username}</td>
                    <td>{item.key}</td>
                    <td>{item.usage} / {item.limit}</td>
                    <td>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => handleEdit(item.id)}
                      >
                        <i className="bi bi-pencil-fill"></i> Edit
                      </button>
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => handleDelete(item.id)}
                      >
                        <i className="bi bi-trash-fill"></i> Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default DataTable;
