import React from 'react';
import malspyLogo from './images/malspy logo.png'; 

const Footer = () => (
  <div className="footer bg-primary text-light mt-5 container-fluid" style={{ width: '100%' }}>
    <div className="container-fluid px-0 m-0">
      <div className="row mx-0 text-center text-md-left">
        
        {/* First Column: Logo and Text */}
        <div className="col-12 col-md-3 mb-4 d-flex flex-column align-items-center justify-content-center">
          <img
            src={malspyLogo}
            alt="Malspy Logo"
            className="img-fluid mb-2"
            style={{ width: '60px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }}
          />
          <h5 className="font-weight-bold mt-3">Malspy</h5>
          <p className="small text-center">
            Pioneering network optimization that improves speed, reduces latency and congestion – along with full visibility.
          </p>
        </div>

        {/* Second Column: Product, General, and Social in one div */}
        <div className="col-12 col-md-9 mb-4 d-flex justify-content-between">
          
          {/* Product Section */}
          <div className="col-4">
            <h6 className="text-gold font-weight-bold">Product</h6>
            <ul className="list-unstyled">
              <li><a href="#" className="text-light footer-link">TCP Optimization</a></li>
              <li><a href="#" className="text-light footer-link">Bandwidth Management</a></li>
              <li><a href="#" className="text-light footer-link">Network Visibility</a></li>
              <li><a href="#" className="text-light footer-link">SDN Platform</a></li>
            </ul>
          </div>

          {/* General Section */}
          <div className="col-4">
            <h6 className="text-gold font-weight-bold">General</h6>
            <ul className="list-unstyled">
              <li><a href="#" className="text-light footer-link">Customers</a></li>
              <li><a href="#" className="text-light footer-link">Newsroom</a></li>
              <li><a href="#" className="text-light footer-link">Where to Buy</a></li>
              <li><a href="#" className="text-light footer-link">About</a></li>
              <li><a href="#" className="text-light footer-link">Careers</a></li>
              <li><a href="#" className="text-light footer-link">Contact</a></li>
            </ul>
          </div>

          {/* Social Section */}
          <div className="col-4">
            <h6 className="text-gold font-weight-bold">Social</h6>
            <ul className="list-unstyled text-center">
              <li><a href="#" className="text-light footer-link">LinkedIn</a></li>
              <li><a href="#" className="text-light footer-link">Twitter</a></li>
              <li><a href="#" className="text-light footer-link">Facebook</a></li>
            </ul>
          </div>

        </div>

      </div>

      {/* Footer Bottom Section */}
      <div className="row border-top pt-3 mt-3 mx-0 text-center text-md-left">
        <div className="col-12 col-md-6">
          <a href="#" className="text-light small footer-link">Documentation</a>
        </div>
        <div className="col-12 col-md-6 text-center text-md-right">
          <a href="#" className="text-light small footer-link">Privacy & Cookie Policy</a>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
