import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";

// Main Website Components
import NavbarSection from "./components/NavbarSection";
import Timeline from "./components/Timeline";
import ThreatSeverity from "./components/ThreatSeverity";
import Footer from "./components/Footer";
import Result from "./results";
import Support from "./components/Support";
// Dashboard Components
import Login from "./dashboard/Login";
import Dashboard from "./dashboard/Dashboard";
import DataTable from "./dashboard/DataTable";
import NewUser from "./dashboard/NewUser";
import Customers from "./dashboard/Customers";
import Logout from "./dashboard/Logout";

// Styles
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./style.css"; // Ensure styles for full-screen layout

// Authentication state simulation (replace with actual logic)
const isAuthenticated = () => {
    // Example logic; replace with real authentication check
    return localStorage.getItem("authToken") !== null;
};

// Protected Route Component
const PrivateRoute = ({ children }) => {
    return isAuthenticated() ? children : <Navigate to="/dashboard/login" />;
};

// Helper function to decode and sanitize the domain
const sanitizeDomain = (url) => {
    try {
        let decodedUrl = decodeURIComponent(url);
        decodedUrl = decodedUrl.replace(/(^\w+:|^)\/\//, ""); // Remove protocol
        return decodedUrl.replace(/[^a-zA-Z0-9.-]/g, ""); // Keep only valid characters
    } catch (error) {
        console.error("Error decoding URI:", error);
        return ""; // Return empty string if decoding fails
    }
};

// Layout Component
const Layout = () => {
    const location = useLocation();

    const isDashboard = location.pathname.startsWith("/dashboard");
    const isSupportPage = location.pathname === "/support";
    const isResultsPage = location.pathname.startsWith("/results");

    return (
        <>
     {/* Public Section */}
{!isDashboard && !isSupportPage && !isResultsPage && (
    <>
        <div className="container-fluid">
            <NavbarSection />
            <Timeline />
            <ThreatSeverity />
            <Routes>
                {/* Add your public routes here */}
            </Routes>
        </div>
        <Footer /> {/* Footer is outside the container-fluid div */}
    </>
)}

    
            {/* Results Section */}
{isResultsPage && (
    <>
        <div
            className="container-fluid results-page"
            style={{ backgroundColor: "white", backgroundImage: "none" }}
        >
            <Routes>
                <Route path="/results/:domain" element={<Result />} />
            </Routes>
        </div>
        <Footer /> {/* Footer placed outside the container-fluid div */}
    </>
)}
    
            {/* Support Section */}
            {isSupportPage && (
                <div className="container-fluid full-width-container">
                    {/* Uncomment or import Support if required */}
                    <Support />
                    <Footer />
                </div>
            )}
    
            {/* Dashboard Section */}
            {isDashboard && (
                <div className="dashboard-container">
                    <Routes>
                        {/* Public Dashboard Route */}
                        <Route path="/dashboard/login" element={<Login />} />
    
                        {/* Protected Dashboard Routes */}
                        <Route
                            path="/dashboard/*"
                            element={
                                <PrivateRoute>
                                    <Routes>
                                        <Route path="/" element={<Dashboard />} />
                                        <Route path="data-table" element={<DataTable />} />
                                        <Route path="new-user" element={<NewUser />} />
                                        <Route path="customers" element={<Customers />} />
                                        <Route path="logout" element={<Logout />} />
                                    </Routes>
                                </PrivateRoute>
                            }
                        />
                    </Routes>
                </div>
            )}
        </>
    );
    
};

// Main App Component
function App() {
    return (
        <Router>
            <Layout />
        </Router>
    );
}

export default App;
