import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Dashboard.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

const NewUser = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    phone_number: "", // Match backend field name
    daily_limit: "",  // Match backend field name
    monthly_limit: "", // Match backend field name
    status: "active",
    apiKey: "",
  });
  const [loading, setLoading] = useState(false);

  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const generateApiKey = async () => {
    setLoading(true);
    try {
      const response = await fetch("https://test.malspy.com/api/generate-api-key/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Error from server:", errorText);
        alert("Failed to generate API key. Please try again.");
        setLoading(false);
        return;
      }

      const data = await response.json();
      setFormData((prevData) => ({
        ...prevData,
        apiKey: data.api_key, // Update the API key field with the generated key
      }));
      alert("API Key generated successfully!");
    } catch (error) {
      console.error("Error generating API key:", error);
      alert("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch("https://test.malspy.com/api/process-new-user/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Error from server:", errorText);
        alert("Failed to add user. Please try again.");
        return;
      }

      alert("User added successfully!");
      setFormData({
        username: "",
        email: "",
        phone_number: "",
        daily_limit: "",
        monthly_limit: "",
        status: "active",
        apiKey: "",
      });
    } catch (error) {
      console.error("Error adding user:", error);
      alert("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className={`dashboard ${sidebarCollapsed ? "collapsed" : ""}`}>
      {/* Sidebar */}
      <div id="sidebar" className={`sidebar ${sidebarCollapsed ? "collapsed" : ""}`}>
        <div className="logo">
          <span className={`full-text ${sidebarCollapsed ? "d-none" : ""}`}>Malspy</span>
          <button className="toggle-btn" onClick={toggleSidebar}>
            &#9776;
          </button>
        </div>
        <nav className="nav flex-column">
          <Link className="nav-link" to="/dashboard/">
            <span className="icon">&#128178;</span>
            <span className={`text ${sidebarCollapsed ? "d-none" : ""}`}>Packages</span>
          </Link>
          
          <Link className="nav-link" to="/dashboard/new-user">
            <span className="icon">&#128100;</span>
            <span className={`text ${sidebarCollapsed ? "d-none" : ""}`}>New User</span>
          </Link>
          <Link className="nav-link" to="/dashboard/customers">
            <span className="icon">&#128179;</span>
            <span className={`text ${sidebarCollapsed ? "d-none" : ""}`}>Customers</span>
          </Link>
          <Link className="nav-link" to="/logout">
            <span className="icon">&#128682;</span>
            <span className={`text ${sidebarCollapsed ? "d-none" : ""}`}>Logout</span>
          </Link>
        </nav>
      </div>

      {/* Content */}
      <div id="content" className="content">
        <section id="new-user-section" className="mt-4">
          <h2 className="text-center mb-4">Add New User</h2>
          <form onSubmit={handleSubmit} className="mt-4 w-100">
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
              value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="number" className="form-label">
                Phone Number
              </label>
              <input
                type="text"
                className="form-control"
                id="number"
                name="phone_number" // Match backend field name
              value={formData.phone_number}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="limit" className="form-label">
                Daily Limit
              </label>
              <input
                type="text"
                className="form-control"
                id="limit"
                name="daily_limit" // Match backend field name
                value={formData.daily_limit}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="montlylimit" className="form-label">
                Monthly Limit
              </label>
              <input
                type="text"
                className="form-control"
                id="montlylimit"
                name="monthly_limit" // Match backend field name
                value={formData.monthly_limit}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="package" className="form-label">
                Status
              </label>
              <select
                className="form-select"
                id="package"
                name="status"
              value={formData.status}
                onChange={handleInputChange}
                required
              >
                <option value="active">Active</option>
                <option value="off">Off</option>
              </select>
            </div>

            <button type="button" onClick={generateApiKey} disabled={loading}>
            {loading ? 'Generating...' : 'Generate API Key'}
          </button>
          </form>
          {formData.apiKey && (
          <div>
            <h3>Generated API Key:</h3>
            <p>{formData.apiKey}</p>
          </div>
        )}
        </section>
      </div>
    </div>
  );
};

export default NewUser;
