import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Dashboard.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

const Customers = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true); // To handle loading state
  const [error, setError] = useState(null); // To handle errors
  const navigate = useNavigate(); // For navigation

  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  useEffect(() => {
    // Fetch data from the backend
    const fetchCustomers = async () => {
      try {
        const response = await fetch("http://127.0.0.1:8000/api/get-all-keys/"); // Backend API endpoint
        if (!response.ok) {
          throw new Error("Failed to fetch customer data.");
        }
        const data = await response.json();
        setCustomers(data); // Assuming the API returns a JSON array of customer objects
      } catch (error) {
        console.error("Error fetching customer data:", error);
        setError("Failed to fetch customers. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchCustomers();
  }, []); // Empty dependency array ensures the effect runs once when the component mounts

  const changeStatus = async (keyId, currentStatus) => {
    const confirmChange = window.confirm(
      `Do you want to ${currentStatus === "active" ? "inactivate" : "activate"} the key?`
    );
    if (!confirmChange) return;

    try {
      const response = await fetch(`http://127.0.0.1:8000/api/update-key-status/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key_id: keyId,
          status: currentStatus === "active" ? "inactive" : "active",
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update key status.");
      }

      const updatedCustomers = customers.map((customer) =>
        customer.key === keyId ? { ...customer, status: currentStatus === "active" ? "inactive" : "active" } : customer
      );
      setCustomers(updatedCustomers);
    } catch (error) {
      console.error("Error updating key status:", error);
      alert("Failed to update key status. Please try again.");
    }
  };

  const handleEdit = (id) => {
    navigate(`/dashboard/EditUser/${id}`); // Navigate to an edit form or page
  };

  const handleDelete = async (apiKey) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this key?");
    if (!confirmDelete) return;
  
    try {
      const response = await fetch(`http://127.0.0.1:8000/api/delete-key/${apiKey}/`, {
        method: "DELETE",
      });
  
      if (!response.ok) {
        const errorData = await response.json(); // Fetch error details
        throw new Error(errorData.error || "Failed to delete the key.");
      }
  
      // Update the state to reflect the deleted key
      setCustomers(customers.filter((customer) => customer.key !== apiKey));
      alert("Key deleted successfully!");
    } catch (error) {
      console.error("Error deleting key:", error);
      alert("Failed to delete the key. Please try again.");
    }
  };

  return (
    <div className={`dashboard ${sidebarCollapsed ? "collapsed" : ""}`}>
      {/* Sidebar */}
      <div id="sidebar" className={`sidebar ${sidebarCollapsed ? "collapsed" : ""}`}>
        <div className="logo">
          <span className={`full-text ${sidebarCollapsed ? "d-none" : ""}`}>Malspy</span>
          <button className="toggle-btn" onClick={toggleSidebar}>
            &#9776;
          </button>
        </div>
        <nav className="nav flex-column">
          <Link className="nav-link" to="/dashboard/">
            <span className="icon">&#128178;</span>
            <span className={`text ${sidebarCollapsed ? "d-none" : ""}`}>Packages</span>
          </Link>
         
          <Link className="nav-link" to="/dashboard/new-user">
            <span className="icon">&#128100;</span>
            <span className={`text ${sidebarCollapsed ? "d-none" : ""}`}>New User</span>
          </Link>
          <Link className="nav-link" to="/dashboard/customers">
            <span className="icon">&#128179;</span>
            <span className={`text ${sidebarCollapsed ? "d-none" : ""}`}>Customers</span>
          </Link>
          <Link className="nav-link" to="/logout">
            <span className="icon">&#128682;</span>
            <span className={`text ${sidebarCollapsed ? "d-none" : ""}`}>Logout</span>
          </Link>
        </nav>
      </div>

      {/* Content */}
      <div id="content" className="content">
        <section id="users-section" className="mt-4">
          <h2 className="text-center mb-4">User API Usage</h2>
          {loading ? (
            <p className="text-center">Loading...</p>
          ) : error ? (
            <p className="text-center text-danger">{error}</p>
          ) : (
            <div className="table-responsive" style={{ width: "95%", margin: "0 auto" }}>
              <table className="table table-striped futuristic-table">
                <thead>
                  <tr>
                    <th><i className="bi bi-person-fill"></i> User</th>
                    <th><i className="bi bi-key-fill"></i> API Key</th>
                    <th><i className="bi bi-graph-up-arrow"></i> Daily Usage</th>
                    <th><i className="bi bi-calendar2-week"></i> Monthly Usage</th>
                    <th><i className="bi bi-percent"></i> Usage %</th>
                    <th><i className="bi bi-calendar"></i> Created At</th>
                    <th><i className="bi bi-patch-check"></i> Status</th>
                    <th><i className="bi bi-tools"></i> Action</th>
                  </tr>
                </thead>
                <tbody>
                  {customers.map((customer, index) => {
                    const usagePercentage = ((customer.monthly_usage / customer.monthly_limit) * 100).toFixed(2);
                    return (
                      <tr key={index}>
                        <td>{customer.username}</td>
                        <td>{customer.key}</td>
                        <td>{customer.daily_usage} / {customer.daily_limit}</td>
                        <td>{customer.monthly_usage} / {customer.monthly_limit}</td>
                        <td>{usagePercentage}%</td>
                        <td>{new Date(customer.created_at).toLocaleDateString()}</td>
                        <td
                          style={{
                            color: customer.status === "active" ? "green" : "red",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() => changeStatus(customer.key, customer.status)}
                        >
                          {customer.status}
                        </td>
                        <td>
                          
                          <i
                            className="bi bi-trash"
                            style={{ cursor: "pointer", color: "red" }}
                            onClick={() => handleDelete(customer.key)}
                          ></i>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </section>
      </div>
    </div>
  );
};

export default Customers;
