import React from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            const response = await fetch("http://127.0.0.1:8000/api/logout/", {
                method: "POST",
                credentials: "include", // Include cookies
            });

            if (response.ok) {
                localStorage.removeItem("authToken"); // Clear authentication token
                navigate("/dashboard/login"); // Redirect to the login page
            } else {
                console.error("Logout failed");
            }
        } catch (error) {
            console.error("Error during logout:", error);
        }
    };

    return (
        <div className="logout-container">
            <div className="logout-box">
                <h2>Are you sure you want to logout?</h2>
                <div className="buttons">
                    <button onClick={handleLogout} className="btn btn-danger">
                        Logout
                    </button>
                    <button onClick={() => navigate(-1)} className="btn btn-secondary">
                        Cancel
                    </button>
                </div>
            </div>

            <style>{`
                .logout-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100vh;
                    background-color: #f8f9fa;
                }

                .logout-box {
                    background: #fff;
                    padding: 20px 40px;
                    border-radius: 10px;
                    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
                    text-align: center;
                }

                h2 {
                    margin-bottom: 20px;
                }

                .buttons button {
                    margin: 0 10px;
                    padding: 10px 20px;
                    font-size: 16px;
                    border-radius: 5px;
                }

                .btn-danger {
                    background-color: #e74c3c;
                    color: #fff;
                    border: none;
                    transition: background-color 0.3s;
                }

                .btn-danger:hover {
                    background-color: #c0392b;
                }

                .btn-secondary {
                    background-color: #95a5a6;
                    color: #fff;
                    border: none;
                    transition: background-color 0.3s;
                }

                .btn-secondary:hover {
                    background-color: #7f8c8d;
                }
            `}</style>
        </div>
    );
};

export default Logout;
