import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import newImage from './images/new.png';

const NavbarSection = () => {
  const [domain, setDomain] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const normalizeDomain = (input) => {
    return input.replace(/^https?:\/\//, '').replace(/\/$/, '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!domain) {
      setError('Please enter a valid domain.');
      return;
    }

    const normalizedDomain = normalizeDomain(domain);

    setLoading(true);
    try {
      const apiKey = '147bcc52-a6de-47aa-bb4f-3afe4bb1d79b';

      const response = await axios.post(
        'https://test.malspy.com/api/website-info/',
        { domain: normalizedDomain },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-API-KEY': apiKey,
          },
        }
      );

      console.log('Backend Response:', response.data);

      navigate(`/results/${encodeURIComponent(normalizedDomain)}`, { state: { result: response.data } });
    } catch (err) {
      console.error(err);
      setError('An error occurred while fetching results.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid first">
      {/* Loader */}
      {loading && (
        <>
         <style>
  {`
    .loader-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(1, 8, 64, 0.5); /* Updated to use rgba for transparency */
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9999;
    }

    .radiating-loader {
      width: 20px;
      height: 20px;
      background-color: #ffffff;
      border-radius: 50%;
      animation: radiate 1.5s infinite ease-in-out;
    }

    @keyframes radiate {
      0% {
        transform: scale(1);
        opacity: 1;
      }
      50% {
        transform: scale(1.5);
        opacity: 0.5;
      }
      100% {
        transform: scale(2);
        opacity: 0;
      }
    }
  `}
</style>


<div className="loader-overlay">
  <div className="radiating-loader"></div>
</div>

        </>
      )}

      {/* Navbar */}
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img
              src={newImage}
              alt="Malspy Logo"
              className="img-fluid"
              style={{ margin: '0' }}
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item me-3">
                <a className="nav-link active" aria-current="page" href="#">
                  Products
                </a>
              </li>
              <li className="nav-item me-3">
                <a className="nav-link" href="#">
                  Solutions
                </a>
              </li>
              <li className="nav-item me-3">
                <a className="nav-link" href="#">
                  Plans & Pricings
                </a>
              </li>
              <li className="nav-item dropdown me-3">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Partners
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#">
                      Action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Another action
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Something else here
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item me-3">
                <a className="nav-link" href="#">
                  About Us
                </a>
              </li>
            </ul>
            <div className="d-flex ms-auto gap-2">
            <button className="btn btn-scan" onClick={() => navigate('/support')}> Support </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <div className="container mt-5">
        <div className="row">
          <h1 className="scanner-text">
            Online <span className="grn">Scanner</span>
          </h1>
        </div>
        <div className="row">
          <p className="fwms">Free Website Malware Scanner</p>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="container mt-3 mb-5">
        <div className="row">
          <div className="col-md-6">
            <input
              type="search"
              className="form-control search-input"
              placeholder="Enter Your URL..."
              aria-label="Search"
              value={domain}
              onChange={(e) => setDomain(e.target.value)}
            />
          </div>
          <div className="col-md-2">
            <button className="btn btn-scan w-100 h-100" disabled={loading}>
              Scan For Malware
            </button>
          </div>
        </div>
      </form>

      {error && (
        <div className="container">
          <p className="text-danger">{error}</p>
        </div>
      )}

      <div className="container mt-5">
        <div className="col-md-8">
          <p className="gry-text">
            <span className="Disclaimer">Disclaimer :</span> This free automatic
            remote service is provided "AS IS". 100% detection rate does not
            exist and no vendor in the market can guarantee it. Quttera has no
            responsibility for detecting or not detecting malicious code on your
            website or any other websites. If you want your website to be reviewed
            manually please select from website monitoring plans
          </p>
        </div>
      </div>
    </div>
  );
};

export default NavbarSection;
