import React from 'react';
import mal from './images/malspy logo.png';
import newmage from './images/new.png';
import '../support.css';

const Support = () => {
  return (
    <div>
       <nav className="navbar navbar-expand-lg" style={{ background: '#004777' }}>
        <div className="container-fluid">
          <img src={newmage} alt="Description" />
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item me-3">
                <a className="nav-link" href="#">
                  How it Works
                </a>
              </li>
              <li className="nav-item me-3">
                <a className="nav-link" href="#">
                  Validation Process
                </a>
              </li>
              <li className="nav-item me-3">
                <a className="nav-link" href="#">About Us</a>
              </li>
            </ul>
            <div className="d-flex ms-auto gap-2">
              <button className="btn btn-scan">Support</button>
            </div>
          </div>
        </div>
      </nav>

      <div className="contact-section mt-5  p-4" style={{ backgroundColor: "#213163", color: "white", borderRadius: "10px", background: "linear-gradient(to bottom, #213163, #5b7abf)" }}>
  <div className="row justify-content-center align-items-stretch mt-4">
    {/* Form Section */}
    <div className="col-md-5 p-4 bg-transparent  rounded-lg d-flex flex-column">
      <h4 className="mb-4 text-center" style={{ fontWeight: "bold", color: "white", fontSize: "1.5rem" }}>Get in Touch</h4>
      <form className="d-flex flex-column flex-grow-1 justify-content-between">
        <div className="form-group mb-3">
          <label htmlFor="name" style={{ fontWeight: "bold", color: "white" }}>Full Name</label>
          <input type="text" className="form-control form-control-sm" id="name" placeholder="Enter your full name" required style={{ backgroundColor: "#f0f0f0", color: "#333" }} />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="email" style={{ fontWeight: "bold", color: "white" }}>Email Address</label>
          <input type="email" className="form-control form-control-sm" id="email" placeholder="Enter your email address" required style={{ backgroundColor: "#f0f0f0", color: "#333" }} />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="subject" style={{ fontWeight: "bold", color: "white" }}>Subject</label>
          <input type="text" className="form-control form-control-sm" id="subject" placeholder="Enter the subject" required style={{ backgroundColor: "#f0f0f0", color: "#333" }} />
        </div>
        <div className="form-group mb-4">
          <label htmlFor="message" style={{ fontWeight: "bold", color: "white" }}>Message</label>
          <textarea className="form-control form-control-sm" id="message" rows="5" placeholder="Your message here..." required style={{ backgroundColor: "#f0f0f0", color: "#333" }}></textarea>
        </div>
        <button type="submit" className="btn btn-primary btn-lg w-100">Send Message</button>
      </form>
    </div>

    {/* Map Section */}
    <div className="col-md-5 p-4  mt-4 mt-md-0 d-flex flex-column">
      <h4 className="mb-4 text-center " style={{ fontWeight: "bold", color: "#333", fontSize: "1.5rem",  color: "white" }}>Our Location</h4>
      <div className="map-container flex-grow-1" style={{ width: "100%", height: "100%", position: "relative", overflow: "hidden", borderRadius: "12px" }}>
        <iframe
          className="w-100 h-100"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8314.529353327636!2d85.0960009649049!3d25.596124127686934!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f29937c52d4f05%3A0x831a0e05f607b270!2sPatna%2C%20Bihar%2C%20India!5e0!3m2!1sen!2s!4v1731499431466!5m2!1sen!2s"
          style={{ border: "none", borderRadius: "12px" }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  </div>
</div>


    </div>
  );
};

export default Support;