import React from "react";

const Timeline = () => (
    <div className="timeline-main">
        <h3 className="timeline-head">How Website Malware Scanner Works?</h3>
        <div className="timeline-container">
            <ul className="timeline-list">
                {[{ title: "FrontEnd Developer", text: "Website Malware Scanner is a cloud-based application...", date: "January 2021" },
                { title: "BackEnd Developer", text: "It is absolutely free...", date: "February 2021" },
                { title: "Full Stack Developer", text: "Your request will be sent to our scanning server...", date: "March 2021" },
                { title: "App Developer", text: "The malware assessment will contain a breakdown...", date: "April 2021" }].map((item, index) => (
                    <li className="timeline-item" key={index}>
                        <h3 className="timeline-heading">{item.title}</h3>
                        <p>{item.text}</p>
                        <a href="#">Read More &gt;</a>
                        <span className="timeline-date">{item.date}</span>
                        <span className="timeline-circle"></span>
                    </li>
                ))}
            </ul>
        </div>
    </div>
);

export default Timeline;
