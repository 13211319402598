import React from "react";
import svgImage1 from './images/svg-image-1.svg'; // Correct relative path from components folder to images folder
import svgImage2 from './images/svg-image-2.svg'; 
import svgImage3 from './images/svg-image-3.svg'; 
import malspyLogo from './images/malspy logo.png'; // Update based on the actual location in src



const ThreatSeverity = () => (
  <div className="container-fluid tri">
    {/* First Section */}
    {/* <div className="row">
      <div className="col-md-1"></div>
      <div className="col-md-10 tri-section">
        <p className="tst">
        <img src={svgImage1} alt="svg" className="img-fluid" />  &nbsp;&nbsp; Threat severity types
        </p>
        <p className="padd det">
          There are four(4) groups arranged in accordance to the level of maliciousness of each detection in
          group. The constraints were not to create too many groups but to give an average user a clear picture
          on what's behind the code and how dangerous it might be.
        </p>
        <p className="padd sml">Each scan report is assigned one of the following severity types:</p>
        <p className="padd det">
          <i className="fa-solid fa-check"></i> Clean
        </p>
        <p className="padd det">
          <i className="fa-solid fa-check"></i> Potentially Suspicious
        </p>
        <p className="padd det">
          <i className="fa-solid fa-check"></i> Suspicious
        </p>
        <p className="padd det">
          <i className="fa-solid fa-check"></i> Malicious
        </p>
      </div>
      <div className="col-md-1"></div>
    </div> */}
  <div className="row pt-5 pb-5 d-flex justify-content-center">
        {/* Second Section */}
        <div className="col-md-5 tri-section">
          <p className="tst">
            <img src={svgImage2} alt="svg" className="img-fluid" /> &nbsp;&nbsp; Threat severity types
          </p>
          <p className="padd det">
            There are four(4) groups arranged in accordance to the level of maliciousness of each detection in
            group. The constraints were not to create too many groups but to give an average user a clear picture
            on what's behind the code and how dangerous it might be.
          </p>
          <p className="padd det">
            <i className="fa-solid fa-check"></i> Clean
          </p>
          <p className="padd det">
            <i className="fa-solid fa-check"></i> Potentially Suspicious
          </p>
        </div>
        <div className="col-md-1 "></div>
        {/* Third Section */}
        <div className="col-md-5 tri-section">
          <p className="tst">
            <img src={svgImage3} alt="svg" className="img-fluid" /> &nbsp;&nbsp; Threat severity types
          </p>
          <p className="padd det">
            There are four(4) groups arranged in accordance to the level of maliciousness of each detection in
            group. The constraints were not to create too many groups but to give an average user a clear picture
            on what's behind the code and how dangerous it might be.
          </p>
          <p className="padd det">
            <i className="fa-solid fa-check"></i> Clean
          </p>
          <p className="padd det">
            <i className="fa-solid fa-check"></i> Potentially Suspicious
          </p>
        </div>
      </div>


    
      
  </div>

  
);

export default ThreatSeverity;
