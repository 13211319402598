import React from "react";
import { Link, Routes, Route } from "react-router-dom";
import DataTable from "./DataTable";
import NewUser from "./NewUser";
import Customers from "./Customers";
import Logout from "./Logout";
import "bootstrap/dist/css/bootstrap.min.css";

const Dashboard = () => {
  const toggleSidebar = () => {
    const sidebar = document.getElementById("sidebar");
    const content = document.getElementById("content");
    const toggleBtn = document.getElementById("toggle-btn");
    sidebar.classList.toggle("collapsed");
    content.classList.toggle("collapsed");

    // Toggle the button visibility based on collapsed state
    if (sidebar.classList.contains("collapsed")) {
      toggleBtn.style.visibility = "visible";
    } else {
      toggleBtn.style.visibility = "hidden";
    }
  };

  return (
    <div className="dashboard">
      {/* Sidebar */}
      <div id="sidebar" className="sidebar">
        <div className="logo">
          <span className="full-text">Malspy</span>
          <button
            id="toggle-btn"
            className="toggle-btn"
            onClick={toggleSidebar}
          >
            &#9776;
          </button>
        </div>
        <nav className="nav flex-column">
          <Link className="nav-link" to="/dashboard/">
            <span className="icon">&#128178;</span>
            <span className="text">Packages</span>
          </Link>
         
          <Link className="nav-link" to="/dashboard/new-user">
            <span className="icon">&#128100;</span>
            <span className="text">New User</span>
          </Link>
          <Link className="nav-link" to="/dashboard/customers">
            <span className="icon">&#128179;</span>
            <span className="text">Customers</span>
          </Link>
          <Link className="nav-link" to="/logout">
            <span className="icon">&#128682;</span>
            <span className="text">Logout</span>
          </Link>
        </nav>
      </div>

      {/* Content */}
      <div id="content" className="contents ">
        <section id="main-section">
          <h1>Welcome to the Malspy Dashboard</h1>
          <p>Make Scanning Easy.</p>
        </section>

        {/* Packages Section */}
        <section id="packages-section d-flex justify-center" className="mt-4">
          <h2 className="text-center mb-4">Choose Your Package</h2>
          <div className="row">
            {/* Monthly Package */}
            <div className="col-md-6 mb-3">
              <div className="card futuristic-card">
                <div className="card-body">
                  <div className="package-icon">
                    <i className="bi bi-calendar-month"></i>
                  </div>
                  <h4 className="card-title text-center">Monthly Package</h4>
                  <p className="price text-center">$20/month</p>
                  <ul className="features">
                    <li>
                      <i className="bi bi-check-circle"></i> 1000 API requests
                    </li>
                    <li>
                      <i className="bi bi-check-circle"></i> Basic Support
                    </li>
                  </ul>
                  <div className="text-center mt-3">
                    <button className="btn btn-primary">Subscribe Now</button>
                  </div>
                </div>
              </div>
            </div>

            {/* Yearly Package */}
            <div className="col-md-6 mb-3">
              <div className="card futuristic-card">
                <div className="card-body">
                  <div className="package-icon">
                    <i className="bi bi-calendar-range"></i>
                  </div>
                  <h4 className="card-title text-center">Yearly Package</h4>
                  <p className="price text-center">$200/year</p>
                  <ul className="features">
                    <li>
                      <i className="bi bi-check-circle"></i> 12000 API requests
                    </li>
                    <li>
                      <i className="bi bi-check-circle"></i> Premium Support
                    </li>
                  </ul>
                  <div className="text-center mt-3">
                    <button className="btn btn-primary">Subscribe Now</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Nested Routes */}
      <Routes>
        <Route path="/" element={<Packages />} />
        <Route path="/data-table" element={<DataTable />} />
        <Route path="/customers" element={<Customers />} />
        <Route path="/new-user" element={<NewUser />} />
        <Route path="/logout" element={<Logout />} />
      </Routes>
    </div>
  );
};

const Packages = () => (
  <div>
   
  </div>
);

export default Dashboard;
